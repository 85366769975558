var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-building-overview", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "app-welcome-message" },
            [
              _c("h1", [_vm._v("Uitgangspunten maatregelen berekenen")]),
              _c("p", [
                _vm._v(
                  "Berekend voor meerdere complexen gelijktijdig de maatregelen op basis van uitgangspunten."
                ),
              ]),
              _setup.buildingCount >= 1000
                ? _c(
                    "v-alert",
                    {
                      attrs: {
                        dense: "",
                        text: "",
                        outlined: "",
                        type: "warning",
                        icon: "mdi-alert",
                      },
                    },
                    [_vm._v("Aantal is beperkt tot 1000")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-stepper",
        {
          attrs: { flat: "" },
          model: {
            value: _setup.currentStep,
            callback: function ($$v) {
              _setup.currentStep = $$v
            },
            expression: "currentStep",
          },
        },
        [
          _c(
            "v-stepper-header",
            [
              _c(
                "v-stepper-step",
                { attrs: { complete: _setup.currentStep > 1, step: "1" } },
                [_vm._v(" Selectie complexen")]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                { attrs: { complete: _setup.currentStep > 2, step: "2" } },
                [_vm._v(" Selectie uitgansgpunten ")]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                { attrs: { complete: _setup.currentStep > 3, step: "3" } },
                [_vm._v(" Bevestiging ")]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                { attrs: { complete: _setup.currentStep > 4, step: "4" } },
                [_vm._v(" Resultaat ")]
              ),
            ],
            1
          ),
          _c("br"),
          _c(
            "v-stepper-items",
            [
              _c(
                "v-stepper-content",
                { staticClass: "pa-0", attrs: { step: "1" } },
                [
                  _c("h3", [
                    _vm._v(
                      "Selecteer de complexen waarvoor de maatregelen berekend moeten worden"
                    ),
                  ]),
                  _c(_setup.BuildingGrid, {
                    attrs: {
                      token: _setup.token,
                      "hide-details": "",
                      "hide-score": "",
                      "show-select": "",
                    },
                    on: {
                      "changed-selection": _setup.changedSelection,
                      "fetch-data-complete": _setup.buildingsLoaded,
                    },
                  }),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "form-group",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: {
                                    disabled:
                                      _setup.selectedBuildings.length < 1,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _setup.currentStep = 2
                                    },
                                  },
                                },
                                [_vm._v(" Volgende stap")]
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                { attrs: { text: "", to: _setup.returnPage } },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-cancel")]
                                  ),
                                  _vm._v(" Annuleren "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { staticClass: "pa-0", attrs: { step: "2" } },
                [
                  _c("h3", [
                    _vm._v(
                      "Selecteer de uitgangspunten welke van toepassing zijn"
                    ),
                  ]),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  label: "Ambitie",
                                  items: _setup.spAmbitionsList,
                                  outlined: "",
                                  "item-text": "descr",
                                  "item-value": "key",
                                  "hide-details": "",
                                  "persistent-placeholder": "",
                                  placeholder: "Kies een ambitie",
                                },
                                model: {
                                  value: _setup.ambitionKey,
                                  callback: function ($$v) {
                                    _setup.ambitionKey = $$v
                                  },
                                  expression: "ambitionKey",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  label: "Strategie",
                                  items: _setup.spStrategiesList,
                                  outlined: "",
                                  "item-text": "descr",
                                  "item-value": "key",
                                  "hide-details": "",
                                  "persistent-placeholder": "",
                                  placeholder: "Kies een strategie",
                                },
                                model: {
                                  value: _setup.strategyKey,
                                  callback: function ($$v) {
                                    _setup.strategyKey = $$v
                                  },
                                  expression: "strategyKey",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  label: "Randvoorwaarde",
                                  items: _setup.spConditionsList,
                                  outlined: "",
                                  "item-text": "descr",
                                  "item-value": "key",
                                  "hide-details": "",
                                  "persistent-placeholder": "",
                                },
                                model: {
                                  value: _setup.conditionKey,
                                  callback: function ($$v) {
                                    _setup.conditionKey = $$v
                                  },
                                  expression: "conditionKey",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    { attrs: { "pt-5": "", fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "form-group",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: { disabled: !_setup.changedAll },
                                  on: {
                                    click: function ($event) {
                                      _setup.currentStep = 3
                                    },
                                  },
                                },
                                [_vm._v(" Volgende stap")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      _setup.currentStep = 1
                                    },
                                  },
                                },
                                [_vm._v(" Vorige stap ")]
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                { attrs: { text: "", to: _setup.returnPage } },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-cancel")]
                                  ),
                                  _vm._v(" Annuleren "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { staticClass: "pa-0", attrs: { step: "3" } },
                [
                  _c("h3", { staticClass: "pb-3" }, [_vm._v("Bevestiging")]),
                  _vm._v(" Voor "),
                  _c("strong", [
                    _vm._v(_vm._s(_setup.selectedBuildings.length)),
                  ]),
                  _vm._v(" complexen zullen de maatregelen berekend worden."),
                  _c("br"),
                  _c("br"),
                  _vm._v(" Met de volgende uitganspunten: "),
                  _c("ul", [
                    _c("li", [
                      _vm._v(" Ambitie : "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _setup.findInList(
                              _setup.ambitionKey,
                              _setup.spAmbitionsList
                            )
                          )
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _vm._v(" Strategie : "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _setup.findInList(
                              _setup.strategyKey,
                              _setup.spStrategiesList
                            )
                          )
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _vm._v(" Randvoorwaarde : "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _setup.findInList(
                              _setup.conditionKey,
                              _setup.spConditionsList
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _c("br"),
                  _c("v-alert", { attrs: { outlined: "", type: "warning" } }, [
                    _vm._v(
                      "Bestaande maatregelen voor deze complexen worden verwijderd door deze actie. "
                    ),
                  ]),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "form-group",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: { disabled: _setup.applyBusy },
                                  on: { click: _setup.applyMeasures },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-checkbox-marked-circle")]
                                  ),
                                  _vm._v(" Berekenen en opslaan "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      _setup.currentStep = 2
                                    },
                                  },
                                },
                                [_vm._v(" Vorige stap")]
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                { attrs: { text: "", to: _setup.returnPage } },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-cancel")]
                                  ),
                                  _vm._v(" Annuleren "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { staticClass: "pa-0", attrs: { step: "4" } },
                [
                  _c("h3", { staticClass: "pb-3" }, [_vm._v("Resultaat")]),
                  _setup.applyBusy
                    ? _c("span", { staticClass: "loadingDots" }, [
                        _vm._v("De maatregelen worden berekend"),
                      ])
                    : _vm._e(),
                  !_setup.applyBusy
                    ? _c("span", [
                        _vm._v("De maatregelen zijn berekend en toegevoegd."),
                      ])
                    : _vm._e(),
                  _c("v-simple-table", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { staticClass: "text-left" }, [
                                  _vm._v("Complex"),
                                ]),
                                _c("th", { staticClass: "text-left" }, [
                                  _vm._v("Status"),
                                ]),
                                _c("th", { staticClass: "text-left" }, [
                                  _vm._v("Melding"),
                                ]),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_setup.calculationResult, function (item) {
                                return _c("tr", { key: item.id }, [
                                  _c("td", [_vm._v(_vm._s(item.description))]),
                                  _c(
                                    "td",
                                    [
                                      item.status ===
                                      _setup.CalculationStatus.Busy
                                        ? [
                                            _c(
                                              "span",
                                              { staticClass: "loadingDots" },
                                              [_vm._v("Bezig")]
                                            ),
                                          ]
                                        : item.status ===
                                          _setup.CalculationStatus.Success
                                        ? [_vm._v("Succes")]
                                        : item.status ===
                                          _setup.CalculationStatus.Error
                                        ? [_vm._v("Probleem met rekenkern")]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                  _c("td", [_vm._v(_vm._s(item.message))]),
                                ])
                              }),
                              0
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "form-group",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: {
                                    disabled: _setup.applyBusy,
                                    to: _setup.returnPage,
                                  },
                                },
                                [_vm._v(" Klaar ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      _setup.currentStep = 3
                                    },
                                  },
                                },
                                [_vm._v(" Vorige stap ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }