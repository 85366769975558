import { render, staticRenderFns } from "./ComplexesAddStartingPoint.vue?vue&type=template&id=6ba48c66"
import script from "./ComplexesAddStartingPoint.vue?vue&type=script&lang=ts&setup=true"
export * from "./ComplexesAddStartingPoint.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6ba48c66')) {
      api.createRecord('6ba48c66', component.options)
    } else {
      api.reload('6ba48c66', component.options)
    }
    module.hot.accept("./ComplexesAddStartingPoint.vue?vue&type=template&id=6ba48c66", function () {
      api.rerender('6ba48c66', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/project/ComplexesAddStartingPoint.vue"
export default component.exports