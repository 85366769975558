<script lang="ts" setup>
import { ref, computed, onMounted, getCurrentInstance } from "vue";
import BuildingGrid from "@/components/app/buildings/BuildingGrid.vue";
import { FilterTokenName } from "@/stores/filtertoken";
import handleFilterTokens27 from "@/composables/handleFilterTokens27";
import { getStartingPointOptions, calculateMeasures, severityCode } from "@/services/api/building.api";
import { useUserStore } from "@/stores/user";

const { token } = handleFilterTokens27(FilterTokenName.Buildings);
const userStore = useUserStore();

const applyBusy = ref(false);
const currentStep = ref(1);

const selectedBuildings = ref([]);

const buildingCount = ref(0);

const returnPage = { name: "ComplexOverview" };

const spAmbitionsList = ref([]);
const spStrategiesList = ref([]);
const spConditionsList = ref([]);

const ambitionKey = ref();
const strategyKey = ref();
const conditionKey = ref();

const changedAll = computed(() => ambitionKey.value && strategyKey.value && strategyKey.value != "None" && conditionKey.value);

const calculationsDone = ref(0);
const calculationResult = ref([]);

onMounted(() => {
  fetchOptions();
});

function changedSelection(payload) {
  console.log("BuildingsAddCertified changedSelection ", payload);
  selectedBuildings.value = [...payload.selected];
}

function buildingsLoaded(payload) {
  buildingCount.value = payload.count;
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

//eigenlijk enum
const CalculationStatus = {
  None: 0,
  Busy: 1,
  Success: 2,
  Error: -1,
};

async function applyMeasures() {
  applyBusy.value = true;
  calculationResult.value = selectedBuildings.value.map((item) => {
    return { id: item.id, description: item.description, status: CalculationStatus.None, message: undefined };
  });
  currentStep.value = 4;
  calculationsDone.value = 0;
  const report = [];
  console.log("ComplexesAddStartingPoint applyMeasures ", selectedBuildings.value, calculationResult.value);

  for (let index = 0; index < calculationResult.value.length; index++) {
    let item = calculationResult.value[index];
    item.status = CalculationStatus.Busy;
    const response = await calculateMeasures(item.id, ambitionKey.value, strategyKey.value, conditionKey.value);
    if (response.severity <= severityCode.warning) {
      console.log("ComplexesAddStartingPoint, calculateMeasures: ", response);
      item.status = CalculationStatus.Success;
      item.message = `Maatregelen toegevoegd: ${response.data.measuresAddedCount}`;

      report.push(response.data);
    } else {
      item.status = CalculationStatus.Error;
      item.message = response.message;
      report.push(response.data);

      console.log("ComplexesAddStartingPoint, calculateMeasures problem: ", response.message);
    }
    calculationsDone.value++;
  }
  proxy.$toaster.info(`Berekening afgerond`);
  saveToFile(Date.now().toString(), report);

  applyBusy.value = false;
}

function saveToFile(name, data) {
  if (userStore.hasShowComputeCoreValues) {
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([JSON.stringify(data)], { type: "application/json" }));
    a.download = "rekenkern-" + name + ".json";
    a.click();
  }
}

async function fetchOptions() {
  let spResponse = await getStartingPointOptions();
  spAmbitionsList.value = spResponse.spAmbitionsList.filter((x) => x.key !== "None");
  spStrategiesList.value = spResponse.spStrategiesList.filter((x) => x.key !== "None");
  spConditionsList.value = spResponse.spConditionsList;
  console.log("ComplexesAddStartingPoint, getStartingPointOptions: ", spResponse);
}

function findInList(key, list) {
  const found = list.find((item) => item.key === key);
  return found ? found.descr : undefined;
}
</script>

<template>
  <v-container fluid class="app-building-overview">
    <v-row>
      <v-col class="app-welcome-message">
        <h1>Uitgangspunten maatregelen berekenen</h1>
        <p>Berekend voor meerdere complexen gelijktijdig de maatregelen op basis van uitgangspunten.</p>
        <v-alert v-if="buildingCount >= 1000" dense text outlined type="warning" icon="mdi-alert">Aantal is beperkt tot 1000</v-alert>
      </v-col>
    </v-row>

    <v-stepper v-model="currentStep" flat>
      <v-stepper-header>
        <v-stepper-step :complete="currentStep > 1" step="1"> Selectie complexen</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 2" step="2"> Selectie uitgansgpunten </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 3" step="3"> Bevestiging </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 4" step="4"> Resultaat </v-stepper-step>
      </v-stepper-header>
      <br />
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-0">
          <h3>Selecteer de complexen waarvoor de maatregelen berekend moeten worden</h3>
          <building-grid :token="token" hide-details hide-score show-select @changed-selection="changedSelection" @fetch-data-complete="buildingsLoaded"></building-grid>
          <v-container fluid>
            <v-row>
              <v-col cols="auto" class="form-group">
                <v-btn :disabled="selectedBuildings.length < 1" class="primary" @click="currentStep = 2"> Volgende stap</v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn text :to="returnPage">
                  <v-icon dark left>mdi-cancel</v-icon>
                  Annuleren
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="2" class="pa-0">
          <h3>Selecteer de uitgangspunten welke van toepassing zijn</h3>
          <v-container fluid>
            <v-row>
              <v-col cols="4">
                <v-select
                  label="Ambitie"
                  :items="spAmbitionsList"
                  outlined
                  item-text="descr"
                  item-value="key"
                  v-model="ambitionKey"
                  hide-details
                  persistent-placeholder
                  placeholder="Kies een ambitie"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-select
                  label="Strategie"
                  :items="spStrategiesList"
                  outlined
                  item-text="descr"
                  item-value="key"
                  v-model="strategyKey"
                  hide-details
                  persistent-placeholder
                  placeholder="Kies een strategie"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-select label="Randvoorwaarde" :items="spConditionsList" outlined item-text="descr" item-value="key" v-model="conditionKey" hide-details persistent-placeholder />
              </v-col>
            </v-row>
          </v-container>

          <v-container pt-5 fluid>
            <v-row>
              <v-col cols="auto" class="form-group">
                <v-btn :disabled="!changedAll" class="primary" @click="currentStep = 3"> Volgende stap</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn text @click="currentStep = 1"> Vorige stap </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn text :to="returnPage">
                  <v-icon dark left>mdi-cancel</v-icon>
                  Annuleren
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-content>

        <v-stepper-content step="3" class="pa-0">
          <h3 class="pb-3">Bevestiging</h3>
          Voor <strong>{{ selectedBuildings.length }}</strong> complexen zullen de maatregelen berekend worden.<br />
          <br />
          Met de volgende uitganspunten:
          <ul>
            <li>
              Ambitie : <strong>{{ findInList(ambitionKey, spAmbitionsList) }}</strong>
            </li>
            <li>
              Strategie : <strong>{{ findInList(strategyKey, spStrategiesList) }}</strong>
            </li>
            <li>
              Randvoorwaarde : <strong>{{ findInList(conditionKey, spConditionsList) }}</strong>
            </li>
          </ul>
          <br />
          <v-alert outlined type="warning">Bestaande maatregelen voor deze complexen worden verwijderd door deze actie. </v-alert>
          <v-container fluid>
            <v-row>
              <v-col cols="auto" class="form-group">
                <v-btn :disabled="applyBusy" class="primary" @click="applyMeasures">
                  <v-icon dark left>mdi-checkbox-marked-circle</v-icon>
                  Berekenen en opslaan
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn text @click="currentStep = 2"> Vorige stap</v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn text :to="returnPage">
                  <v-icon dark left>mdi-cancel</v-icon>
                  Annuleren
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="4" class="pa-0">
          <h3 class="pb-3">Resultaat</h3>
          <span v-if="applyBusy" class="loadingDots">De maatregelen worden berekend</span>
          <span v-if="!applyBusy">De maatregelen zijn berekend en toegevoegd.</span>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Complex</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Melding</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in calculationResult" :key="item.id">
                  <td>{{ item.description }}</td>
                  <td>
                    <template v-if="item.status === CalculationStatus.Busy"><span class="loadingDots">Bezig</span></template>
                    <template v-else-if="item.status === CalculationStatus.Success">Succes</template>
                    <template v-else-if="item.status === CalculationStatus.Error">Probleem met rekenkern</template>
                  </td>
                  <td>{{ item.message }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-container fluid>
            <v-row>
              <v-col cols="auto" class="form-group">
                <v-btn :disabled="applyBusy" class="primary" :to="returnPage"> Klaar </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn text @click="currentStep = 3"> Vorige stap </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>
